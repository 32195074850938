<template>
  <div id="header">
    <el-row>
      <el-button
        v-throttle
        @click="
          () => {
            this.$router.push({
              path: '/rollCall/report',
            })
          }
        "
        size="medium"
        icon="el-icon-back"
        class="returnBtn back1"
        round
        >返回</el-button
      >
      <el-button
        v-throttle
        @click="quitBtn"
        size="medium"
        icon="el-icon-switch-button"
        class="returnBtn back2"
        round
        >退出</el-button
      >
      <div @click="mute = !mute" class="returnBtn back3">
        <img v-if="mute" src="@/assets/mute.png" />
        <img v-else src="@/assets/notMute.png" />
      </div>
    </el-row>
    <el-card shadow="always">
      <div id="exam-box">{{ callInfo.examName }}</div>
      <div id="place-box">
        考点：{{ callInfo.sysOrgExamPlaceName }}&nbsp;&nbsp;&nbsp; 当前批次：{{
          sysOrgExamPlaceRoomInfo.examSession
        }}
      </div>
      <div style="margin-top: 10px; line-height: 36px">
        <el-row>
          <span
            >统计：当前已完成考试{{
              callInfo.haveExamExamineeCount
            }}人，未完成考试{{ callInfo.notExamExamineeCount }}人，共{{
              callInfo.examExamineeCount
            }}人</span
          >
          <div style="float: right">
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="historyBatch"
              >查看历史批次</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="batchView"
              >考生信息一览</el-button
            >
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="printInfoFunc"
              :disabled="enterDisabled"
              >打印报到信息</el-button
            >
          </div>
        </el-row>
        <el-row style="margin-top: 10px">
          <div id="examination-room-box">
            <span>选择考场</span>
            <el-select
              v-model="sysOrgExamPlaceRoomId"
              placeholder="请选择考场"
              @change="chooseExamPlaceRoomFunc(true)"
            >
              <el-option
                v-for="item in callBatchPlatformOrgPlaceRoomList"
                :key="item.sysOrgExamPlaceRoomId"
                :label="item.sysOrgExamPlaceRoomName"
                :value="item.sysOrgExamPlaceRoomId"
              ></el-option>
            </el-select>
          </div>
          <el-table
            v-loading="loading"
            stripe
            border
            ref="table"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#DFE6EC',
            }"
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column
              prop="index"
              label="序号"
              width="60"
            ></el-table-column>
            <el-table-column label="考生编号" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-input
                  v-if="!scope.row.examineeCode"
                  v-model="manualCallDialog.examineeCode"
                  @keyup.enter.native="enterCallExaminee(scope.row.index)"
                  :ref="'RowIndex' + scope.row.index"
                ></el-input>
                <span v-if="scope.row.examineeCode">{{
                  scope.row.examineeCode
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="studentName"
              label="考生姓名"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="schoolName"
              label="考生学校"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="schoolClassName"
              label="考生班级"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="seatCode"
              label="座位号"
              show-overflow-tooltip
              v-if="randomTimingDrawType == 1"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.seatCode"
                  placement="top"
                  :hide-after="2000"
                  :value="!hideTooltip"
                  :disabled="hideTooltip"
                  popper-class="tip-class"
                  v-if="hideTooltipIndex == scope.$index + 1"
                >
                  <span>{{ scope.row.seatCode }}</span>
                </el-tooltip>
                <span v-else>{{ scope.row.seatCode }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              min-width="30px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-button
                  v-throttle
                  v-if="scope.row.examineeCode"
                  type="text"
                  style="color: red"
                  @click="removeRow(scope.$index, scope.row)"
                  >移除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="float: right; margin-top: 15px">
            <el-button
              v-throttle
              type="primary"
              size="medium"
              @click="finishExamSession"
              :disabled="enterDisabled"
              >完成批次</el-button
            >
          </div>
        </el-row>
      </div>
    </el-card>
    <!-- A4打印区域 -->
    <vue-easy-print tableShow ref="easyPrint" class="hideDiv">
      <div class="a4TableData">
        <div id="exam-box2">{{ callInfo.examName }}</div>
        <div id="place-box2">
          考点：{{ callInfo.sysOrgExamPlaceName }}&nbsp;&nbsp;&nbsp; 批次：{{
            sysOrgExamPlaceRoomInfo.examSession
          }}
        </div>
        <el-table
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          border
          :data="a4PrintData"
          style="width: 99%"
          :key="subjectTypeShow"
        >
          <el-table-column type="index" label="序号"  width="50"></el-table-column>
          <el-table-column prop="studentName" label="考生姓名"></el-table-column>
          <el-table-column prop="examineeCode" label="准考证号"></el-table-column>
          <el-table-column prop="sysOrgSchoolName" label="学校"></el-table-column>
          <el-table-column prop="sysOrgSchoolClassName" label="班级"></el-table-column>
          <el-table-column
            prop="seatCode"
            label="考生座位号"
            v-if="randomTimingDrawType == 1"
          ></el-table-column>
          <!-- <el-table-column prop="studentCode" label="考生学号"></el-table-column> -->
          <!-- <el-table-column v-if="subjectTypeShow" prop="subjectType" label="科目"></el-table-column> -->
        </el-table>
      </div>
    </vue-easy-print>
  </div>
</template>
<script>
import {
  enterCallApi,
  getBatchExamineeListApi,
  enterCallExamineeApi,
  finishExamSessionApi,
  removeBatchExamineeApi,
  getPrintInfoApi,
} from '@/api/rollCall/rollCall.js'
import { isEmpty } from '@/utils/util.js'
// import cryptoAES from '@/utils/crypto.js'
import { Loading } from 'element-ui'
import vueEasyPrint from 'vue-easy-print'
import { quit } from '@/api/auth.js'
import { removeToken, removeName, getLoginRoad } from '@/utils/token.js'
import { removeStore } from '@/utils/token.js'
export default {
  name: 'centralizeRollCall',
  components: { vueEasyPrint },
  data() {
    return {
      enterDisabled: true,
      //点名信息
      callInfo: {
        examId: 0,
        examName: '',
        sysOrgExamPlaceName: '',
        haveExamExamineeCount: 0,
        notExamExamineeCount: 0,
        examExamineeCount: 0,
        examSession: 0,
        callBatchPlatformSubjectAndPlaceRoomList: null,
      },
      urlParam: {
        examId: 0,
        examPlaceId: 0,
        randomTimingType: 0,
      },
      loading: false,
      tableData: [],
      manualCallDialog: {
        examineeCode: '',
      },
      dataId: 0,
      //聚焦index
      currentFocusIndex: 0,
      //标记操作(0-移除录入 1-录入)
      isDeleted: null,
      // 打印信息
      a4PrintData: [],
      subjectTypeShow: false,
      // 气泡
      hideTooltip: true,
      hideTooltipIndex: 0,
      // 抽签方式（1报到即抽签 2报到后抽签）
      randomTimingDrawType: 1,
      // 多考场
      callBatchPlatformOrgPlaceRoomList: [],
      sysOrgExamPlaceRoomId: null,
      sysOrgExamPlaceRoomInfo: {},
      //
      synth: null,
      utterThis: false,
      mute: false,
    }
  },
  created() {
    this.mute = false
    this.utterThis = false
    this.synth = window.speechSynthesis
  },
  mounted() {
    this.randomTimingDrawType = Number(this.$route.query.randomTimingDrawType)
    console.log('this.randomTimingDrawType', typeof this.randomTimingDrawType)
    this.renderUrlParam()
  },

  methods: {
    renderUrlParam() {
      this.urlParam.examId = 0
      this.urlParam.examId = Number(this.$route.query.examId)
      this.urlParam.examPlaceId = 0
      this.urlParam.examPlaceId = Number(this.$route.query.examPlaceId)
      this.urlParam.randomTimingType = 0
      this.urlParam.randomTimingType = Number(
        this.$route.query.randomTimingType
      )
      this.sysOrgExamPlaceRoomId = Number(
        this.$route.query.sysOrgExamPlaceRoomId
      )
      console.log('this.sysOrgExamPlaceRoomId', this.sysOrgExamPlaceRoomId)
      this.enterCall()
    },
    enterCall() {
      enterCallApi(
        this.urlParam.examId,
        this.urlParam.examPlaceId,
        this.urlParam.randomTimingType
      ).then((res) => {
        if (res.success) {
          this.callInfo = {}
          this.callBatchPlatformOrgPlaceRoomList = []
          this.callInfo = res.data
          this.callInfo.callBatchPlatformSubjectAndPlaceRoomList.forEach(
            (val) => {
              this.callBatchPlatformOrgPlaceRoomList.push(
                ...val.callBatchPlatformOrgPlaceRoomList
              )
            }
          )
          if (!this.sysOrgExamPlaceRoomId) {
            this.sysOrgExamPlaceRoomId =
              this.callBatchPlatformOrgPlaceRoomList[0].sysOrgExamPlaceRoomId
          }
          this.chooseExamPlaceRoomFunc(false)
        } else {
          this.msgError(res.msg)
        }
      })
    },
    //根据参数查询临时录入的学生
    getBatchExamineeList() {
      if (isEmpty(this.callInfo)) {
        return
      }
      let returnData = {
        examId: this.urlParam.examId,
        examSession: this.sysOrgExamPlaceRoomInfo.examSession,
        examSubjectId: 0,
        examineeCode: '',
        randomTimingType: this.urlParam.randomTimingType,
        sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        //
        // groupCode: this.sysOrgExamPlaceRoomInfo.groupCode,
      }
      this.loading = true
      getBatchExamineeListApi(returnData).then((res) => {
        this.loading = false
        if (res.success) {
          this.tableData = []
          this.hideTooltipIndex = 0
          this.hideTooltipIndex = res.data.length
          this.tableData = res.data
          this.tableData.forEach((item, index) => {
            this.$set(item, 'index', index + 1)
          })
          this.utterThis && this.setPlayFunc(this.tableData[this.tableData.length - 1]);
          this.addRow()
        }
        setTimeout(() => {
          this.hideTooltip = true
        }, 3000)
      })
    },
    //临时录入考生
    enterCallExaminee(index) {
      if (isEmpty(this.manualCallDialog.examineeCode.trim())) {
        this.msgWraning('考生编号不能为空')
        return
      }
      this.manualCallDialog.examineeCode =
        this.manualCallDialog.examineeCode.replace(/\s*/g, '')
      this.currentFocusIndex = 0
      this.currentFocusIndex = index
      let returnData = {
        // 录入方式（1考生考号 2学生id 3学生身份证号码）
        enteringType: 1,
        examId: this.urlParam.examId,
        examSession: this.sysOrgExamPlaceRoomInfo.examSession,
        examSubjectId: 0,
        examineeCode: this.manualCallDialog.examineeCode,
        randomTimingType: this.urlParam.randomTimingType,
        sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        //
        // groupCode: this.sysOrgExamPlaceRoomInfo.groupCode,
      }
      enterCallExamineeApi(returnData).then((res) => {
        if (res.success) {
          this.isDeleted = null
          this.isDeleted = 1
          // this.msgSuccess('录入成功')
          this.utterThis = true
          this.enterDisabled = false
          this.getBatchExamineeList()
          this.hideTooltip = false
        } else {
          if (res.code != 500) {
            this.msgError(res.msg)
          }
        }
        this.manualCallDialog.examineeCode = ''
      })
    },
    //完成批次
    finishExamSession() {
      this.$confirm(
        `当前批次共有${this.tableData.length - 1}名考生，确定完成该批次？`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          // 调用打印
          this.printInfoFunc()
          let returnData = {
            examId: this.urlParam.examId,
            examSession: this.sysOrgExamPlaceRoomInfo.examSession,
            examSubjectId: 0,
            randomTimingType: this.urlParam.randomTimingType,
            sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
            //
            // groupCode: this.sysOrgExamPlaceRoomInfo.groupCode,
          }
          finishExamSessionApi(returnData).then((res) => {
            if (res.success) {
              this.msgSuccess('完成批次')
              this.enterCall()
              this.callInfo.examSession += 1
              this.tableData = []
              this.addRow()
            } else {
              if (res.code != 500) {
                this.msgError(res.msg)
              }
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    historyBatch() {
      this.$router.push({
        path: '/rollCall/historyBatch',
        query: {
          examId: this.urlParam.examId,
          examSession: this.sysOrgExamPlaceRoomInfo.examSession,
          examPlaceId: this.urlParam.examPlaceId,
          randomTimingType: this.urlParam.randomTimingType,
          examName: this.callInfo.examName,
          sysOrgExamPlaceName: this.callInfo.sysOrgExamPlaceName,
          randomTimingDrawType: this.randomTimingDrawType,
          //
          sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        },
      })
    },
    batchView() {
      this.$router.push({
        path: '/rollCall/batchView',
        query: {
          examId: this.urlParam.examId,
          examSession: this.sysOrgExamPlaceRoomInfo.examSession,
          examPlaceId: this.urlParam.examPlaceId,
          randomTimingType: this.urlParam.randomTimingType,
          examName: this.callInfo.examName,
          sysOrgExamPlaceName: this.callInfo.sysOrgExamPlaceName,
          randomTimingDrawType: this.randomTimingDrawType,
          //
          sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        },
      })
    },
    addRow() {
      let len = this.tableData.length
      this.dataId = 0
      this.manualCallDialog.examineeCode = ''
      let elem = {
        index: 0,
        examineeCode: '',
        studentName: '',
        schoolName: '',
        schoolClassName: '',
      }
      if (len > 0) {
        this.enterDisabled = false
        elem.index = len + 1
      } else {
        this.enterDisabled = true
        elem.index = ++this.dataId
      }
      this.tableData.push(elem)
      this.$refs.table.setCurrentRow(elem)
      if (!isEmpty(this.currentFocusIndex)) {
        if (this.currentFocusIndex > 0) {
          if (isEmpty(this.isDeleted)) {
            return
          }
          let index = 0
          switch (this.isDeleted) {
            case 0:
              this.tableData.forEach((item) => {
                if (isEmpty(item.examExamineeCount)) {
                  index = item.index
                }
              })
              break
            case 1:
              index = this.currentFocusIndex + 1
              break
          }
          this.$nextTick(() => {
            this.$refs['RowIndex' + index].focus()
          })
        } else {
          this.$nextTick(() => {
            this.$refs['RowIndex1'].focus()
          })
        }
      }
    },
    removeRow(index, row) {
      this.currentFocusIndex = 0
      this.currentFocusIndex = row.index
      this.globalMessageBox(
        `确认删除当前录入考生吗?`,
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          this.utterThis = false
          this.removeBatchExaminee(row)
        })
        .catch(() => {})
    },
    removeBatchExaminee(row) {
      let returnData = {
        examId: this.urlParam.examId,
        examSession: this.sysOrgExamPlaceRoomInfo.examSession,
        examineeCode: row.examineeCode,
        randomTimingType: this.urlParam.randomTimingType,
        sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        //
        // groupCode: this.sysOrgExamPlaceRoomInfo.groupCode,
      }
      removeBatchExamineeApi(returnData).then((res) => {
        if (res.success) {
          this.isDeleted = null
          this.isDeleted = 0
          this.msgSuccess('删除成功')
          this.manualCallDialog.examineeCode = ''
          this.getBatchExamineeList()
        } else {
          if (res.code != 500) {
            this.msgError('删除失败')
          }
        }
      })
    },
    printInfoFunc() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '打印信息加载中',
      })
      let data = {
        examId: this.urlParam.examId,
        examSession: this.sysOrgExamPlaceRoomInfo.examSession,
        examSubjectId: 0,
        randomTimingType: this.urlParam.randomTimingType,
        sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        //
        // groupCode: this.sysOrgExamPlaceRoomInfo.groupCode,
      }
      getPrintInfoApi(data)
        .then((res) => {
          console.log('打印报到信息', res)
          this.$nextTick(() => {
            loadingInstance.close()
          })
          if (res.success) {
            this.a4PrintData = res.data.callBatchPlatformSeatExamineeList
            this.subjectTypeShow = !!this.a4PrintData[0].subjectType
            this.$forceUpdate()
            if (!!this.a4PrintData.length) {
              this.$nextTick(() => {
                this.$refs.easyPrint.print()
              })
            } else {
              this.$message.info('无报到信息')
            }
          }
        })
        .catch((err) => {
          this.$nextTick(() => {
            loadingInstance.close()
          })
          // this.$message.error('请求发送失败')
          console.log('err', err)
        })
    },
    // 退出登录
    quitBtn() {
      quit().then((res) => {
        if (res.success) {
          removeStore('LOCAL_OSS')
          removeStore('LOCAL_MEDIA')
          removeToken()
          removeName()
          setTimeout(() => {
            // if (!getLoginRoad()) {
            // removeLoginRoad()
            this.$router.replace('/selectLogin')
            // } else {
            // removeLoginRoad()
            // this.$router.replace('/cityPlatformLogin')
            // }
          }, 500)
        }
      })
    },
    // 选择考场
    chooseExamPlaceRoomFunc(bl = false) {
      this.callBatchPlatformOrgPlaceRoomList.forEach((item) => {
        if (item.sysOrgExamPlaceRoomId == this.sysOrgExamPlaceRoomId) {
          this.sysOrgExamPlaceRoomInfo = {}
          this.sysOrgExamPlaceRoomInfo = item
        }
      })
      this.$router.replace({
        // path: '/rollCall/centralizeRollCall',
        query: {
          examId: this.urlParam.examId,
          examPlaceId: this.urlParam.examPlaceId,
          randomTimingType: this.urlParam.randomTimingType,
          randomTimingDrawType: this.randomTimingDrawType,
          sysOrgExamPlaceRoomId: this.sysOrgExamPlaceRoomId,
        },
      })

      console.log(
        'sysOrgExamPlaceRoomId',
        this.sysOrgExamPlaceRoomId,
        this.sysOrgExamPlaceRoomInfo
      )
      bl && this.enterCall()
      //根据参数查询临时录入的学生
      this.getBatchExamineeList()
    },
    // 播放音频 设置弹框
    setPlayFunc(val) {
      // 弹框
      this.$notify({
        title: '报到成功',
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: `<div style='font-size: 20px;'>考生编号: ${
          val.examineeCode
        }<br>考生姓名：${val.studentName}${
          this.randomTimingDrawType == 1 ? '<br>考生座位：' + val.seatCode : ''
        }</div>`,
        offset: 50,
      })
      this.utterThis = false
      if (this.mute) return
      // 音频播放
      this.synth.speak(
        new SpeechSynthesisUtterance(
          `考生 ${val.studentName} 报到成功 ${
            this.randomTimingDrawType == 1 ? '座位号' + val.seatCode : ''
          }`
        )
      )
    },
  },
  watch: {
    mute(val) {
      val && this.synth.cancel()
    },
  },
}
</script>
<style lang="scss" scoped>
#header {
  padding: 0 10px;
  margin-top: 54px;
}

::v-deep .el-card__body {
  padding: 15px;
}

#exam-box,
#exam-box2 {
  font-weight: bold;
  font-size: 20px;
}

#place-box,
#place-box2 {
  margin-top: 10px;
  font-size: 16px;
}
#place-box2 {
  margin: 6px 0;
}

#exam-box,
#exam-box2,
#place-box,
#place-box2 {
  text-align: center;
}
#examination-room-box2 {
  margin: 6px 0;
  text-align: center;
}

::v-deep .el-input__inner {
  text-align: center;
}

.list-border {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  text-align: center;
  border: 2px solid #dddddd;
  min-width: 100px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.call-batch-examinee-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 15%;
  width: 70%;
  flex: 0.95;

  .call-batch-examinee-list-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }

  .call-batch-examinee-list-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;

    .examinee-id {
      line-height: 28px;

      .el-input {
        ::v-deep .el-input__inner {
          height: 28px;
          width: 100%;
          border-radius: 0;
        }
      }
    }
  }
}

.a4TableData {
  width: 1000px;
  box-sizing: border-box;
  /*字体调黑*/
  ::v-deep .el-table {
    font-size: 16px;
    // width: 1000px;
    color: #000000;
  }

  ::v-deep .el-table th {
    font-weight: normal;
    font-size: 18px;
  }

  ::v-deep .el-table thead {
    color: #000000;
  }

  /*边框调黑*/
  ::v-deep .el-table::before {
    background-color: #000000;
  }

  ::v-deep .el-table--border::after {
    background-color: #000000;
  }

  ::v-deep .el-table--border th,
  ::v-deep .el-table--border td {
    border-color: #000000;
  }

  ::v-deep .el-table--group,
  ::v-deep .el-table--border {
    border-color: #000000;
  }

  ::v-deep .el-table th.is-leaf,
  ::v-deep .el-table td {
    border-bottom: 0.5px solid #000000;
  }

  /*减小单元格间距*/

  ::v-deep .el-table td {
    // padding: 0 0;
  }
  /*显示底部边框 估计打印时伪元素把border覆盖了 height设为0隐藏伪元素*/
  ::v-deep .el-table::before {
    height: 0;
  }

  /*显示右边框*/
  ::v-deep .el-table--group::after,
  ::v-deep .el-table--border::after {
    width: 0;
  }
}
.hideDiv {
  position: absolute;
  top: -10000px;
}
.returnBtn {
  position: fixed;
  top: 10px;
}
.back1 {
  left: 20px;
}
.back2 {
  right: 20px;
}
.back3 {
  right: 120px;
  height: 36px;
  img {
    height: 100%;
    width: auto;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.tip-class {
  max-width: 30% !important;
  background-color: #409eff !important; /*设置矩形框的背景颜色*/
}
/* [x-placement^='A'] A指的是el-tooltip标签中的placement的值，此处是bottom。猜测用于动态设置border-A-color。*/
.el-tooltip__popper[x-placement^='top'].tip-class .popper__arrow::after {
  border-top-color: #409eff !important; /*设置指向箭头的颜色*/
}
#examination-room-box {
  margin-bottom: 10px;
}
#examination-room-box > span {
  display: inline-block;
  width: 99px;
  height: 40px;
  background: #dfe6ec;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
}
</style>
