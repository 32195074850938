import { request, noTimeOutReq } from '@/utils/request.js'

//进入点名
export function enterCallApi (examId, sysOrgExamPlaceId, randomTimingType) {
  return request({
    url: `/call/batch/get/examDetail/${examId}/${sysOrgExamPlaceId}/${randomTimingType}`,
    method: 'post',
  })
}

//根据参数查询临时录入的学生
export function getBatchExamineeListApi (data) {
  return request({
    url: `/call/batch/list/examinee`,
    method: 'post',
    data: data
  })
}

//临时录入考生
export function enterCallExamineeApi (data) {
  return request({
    url: `/call/batch/entering/examinee`,
    method: 'post',
    data: data
  })
}

//完成批次
export function finishExamSessionApi (data) {
  return request({
    url: `/call/batch/finish/examSession`,
    method: 'post',
    data: data
  })
}

//考生一览
export function getBatchViewListApi (examId, pageIndex, pageSize, data) {
  return request({
    url: `/call/batch/list/view/examinee/${examId}/${pageIndex}/${pageSize}`,
    method: 'post',
    data: data
  })
}

//移除临时录入考生
export function removeBatchExamineeApi (data) {
  return request({
    url: `/call/batch/remove/examinee`,
    method: 'post',
    data: data
  })
}

//查询考试考生汇总信息
export function getExamineeCountApi (examId) {
  return request({
    url: `/call/batch/get/examinee/count/${examId}`,
    method: 'post',
  })
}

//打印报到信息
export function getPrintInfoApi (data) {
  return noTimeOutReq({
    url: `/call/batch/get/print/info`,
    method: 'post',
    data
  })
}